"use strict";

(function() {
    var layoutThemeFilter = function(index, className) {
        return (className.match (/(^|\s)layout--theme--\S+/g) || []).join(' ');
    };

    var findThemeClass = function($layoutElement) {
        var output = null;
        var classes = $layoutElement.attr('class').split(/\s+/);
        classes.forEach(function(className) {
            if (className.indexOf('layout--theme--') >= 0) {
                output = className;
            }
        });
        return output;
    };

    var parseClass = function(className) {
        var classParts = className.split('--');
        
        if ((classParts[0] !== 'layout' && classParts[1] !== 'theme') || classParts.length !== 4) {
            throw "ThemeConfig: Invalid class porvided to parser";
        }
    
        return {
            type: classParts[2],
            color: classParts[3]
        };
    };

    var ThemeConfigMemento = {
        saveTheme: function(layoutClass) {
            if (window.sessionStorage) {
                window.sessionStorage.setItem(
                    'dashboard-layout-class',
                    layoutClass
                );
            }
        },
        restoreTheme: function($layoutElement) {
            if (window.sessionStorage && $layoutElement.length > 0) {
                var themeClass = window.sessionStorage.getItem(
                    'dashboard-layout-class'
                );
                if (themeClass) {
                    $layoutElement.removeClass(layoutThemeFilter);
                    $layoutElement.addClass(themeClass);

                    var themeConfig = parseClass(themeClass);
                    $layoutElement.trigger('theme-changed', {
                        type: themeConfig.type,
                        color: themeConfig.color
                    });
                }
            }
        }
    };

    function ThemeConfig($layout, $typeSwitches, $colorSwitches, $resetButton) {
        this.$layout = $layout;
        this.$typeSwitches = $typeSwitches;
        this.$colorSwitches = $colorSwitches;
    
        this.initialStyleClass = findThemeClass($layout);
        if (this.initialStyleClass && $resetButton) {
            var _this = this;
            $resetButton.on('click', function() {
                _this.clearClass();
    
                _this.$layout.addClass(_this.initialStyleClass);
    
                _this.updateCheckboxes();

                var parsedClass = parseClass(_this.initialStyleClass);
                _this.$layout
                    .trigger('theme-changed', {
                        type: parsedClass.type,
                        color: parsedClass.color
                    });
            });
        }
    }

    ThemeConfig.prototype.buildClass = function(type, color) {
        return 'layout--theme--' + type + '--' + color;
    };
    
    ThemeConfig.prototype.clearClass = function() {
        this.$layout.removeClass(layoutThemeFilter);
    };
    
    ThemeConfig.prototype.updateCheckboxes = function() {
        var currentThemeClass = findThemeClass(this.$layout);
        var currentThemeConfig = parseClass(currentThemeClass);
    
        this.$typeSwitches.each(function() {
            this.checked = this.value === currentThemeConfig.type;
        });
    
        this.$colorSwitches.each(function() {
            this.checked = this.value === currentThemeConfig.color;
        });
    }
    
    ThemeConfig.prototype.startup = function() {
        var _this = this;
        // Update the Theme Selector
        this.updateCheckboxes();
    
        // Define Change handler
        function switchHandler() {
            var type = _this.$typeSwitches.filter(':checked').val();
            var color = _this.$colorSwitches.filter(':checked').val();
            
            // Clear previous class
            _this.clearClass();
    
            // Generate classs name
            var layoutClass = this.buildClass(type, color);
            // Apply the class and notify listeners
            _this.$layout
                .addClass(layoutClass)
                .trigger('theme-changed', { type: type, color: color });
            // Save the class for page transitions
            ThemeConfigMemento.saveTheme(layoutClass);
        }
    
        // Attach Event Handler
        this.$typeSwitches.on('change', switchHandler.bind(this));
        this.$colorSwitches.on('change', switchHandler.bind(this));
    };

    window.Dashboard = Object.assign({ }, window.Dashboard || { }, {
        ThemeConfig: ThemeConfig,
        ThemeConfigMemento: ThemeConfigMemento
    });    
})();